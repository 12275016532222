import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import {Grid, Box, IconButton, Typography} from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseIcon from '@mui/icons-material/Close';

const drawerBleeding = 0;

const Root = styled('div')(({ theme }) => ({
	height: '100%',
	backgroundColor: theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function SwipeableEdgeDrawer(props) {
  const { window } = props;
  const [open, setOpen] = useState(true);

  const toggleDrawer = (newOpen) => () => {
    if (!newOpen) props.setOpenDrawer(true); else props.setOpenDrawer(false); 
    setOpen(newOpen);
  };

  useEffect(() => {
    
  },[]);

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
		<Root>
			<CssBaseline />
			<Global
				styles={{
					'.MuiDrawer-root > .MuiPaper-root': {
						height: `auto`,
						// overflow: 'visible',
						borderTopLeftRadius: 25,
						borderTopRightRadius: 25
					}
				}}
			/>
			{/* <Box sx={{ textAlign: 'center', pt: 1 }}>
				<Button onClick={toggleDrawer(true)}>Open</Button>
			</Box> */}
			<SwipeableDrawer
				container={container}
				anchor="bottom"
				open={open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				swipeAreaWidth={drawerBleeding}
				disableSwipeToOpen={false}
				ModalProps={{
					keepMounted: true
				}}
			>
				<StyledBox
					sx={{
						position: 'absolute',
						top: -drawerBleeding,
						borderTopLeftRadius: 8,
						borderTopRightRadius: 8,
						visibility: 'visible',
						right: 0,
						left: 0
					}}
				>
					<Puller />
				</StyledBox>
				<StyledBox
					sx={{
						pt: 2,
						px: 2,
						pb: 2,
						height: '100%',
						overflow: 'auto'
					}}
				>
					<Grid container>
						<Grid
							item
							xs={10}
							display="flex"
							justifyContent="left"
							alignItems="center"
							sx={{ borderBottom: '1px solid rgb(0,0,0,0.1)' }}
						>
							<Typography variant="h5">{props.header}</Typography>
						</Grid>
						<Grid
							item
							xs={2}
							display="flex"
							justifyContent="right"
							alignItems="center"
							sx={{ borderBottom: '1px solid rgb(0,0,0,0.1)' }}
						>
							<IconButton aria-label="close" onClick={toggleDrawer(false)}>
								<CloseIcon />
							</IconButton>
						</Grid>
						<Grid item xs={12} pt={2} display="flex" justifyContent="left" alignItems="center">
							{props.component}
						</Grid>
					</Grid>
				</StyledBox>
			</SwipeableDrawer>
		</Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
